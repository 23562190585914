import { DashboardPage } from 'root/types';
import type { FlowEditorSDK, EditorScriptFlowAPI, ManagementActionsBuilder, TFunction } from '@wix/yoshi-flow-editor';
import { MENU_MY_BUSINESS_PANEL_ARTICLE_ID, PARENT_APP_DEF_ID } from 'root/utils/consts';
import { openManageMenuDashboardPanel } from 'root/utils/openManageMenuDashboardPanel';
import { DASHBOARD_LOAD_REFERRAL_INFO } from 'root/utils/bi';

export const configureMyBusinessActions = (
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI,
  managementActionsBuilder: ManagementActionsBuilder
) => {
  const { translations } = flowAPI;
  const t = translations.t as TFunction;

  managementActionsBuilder.mainActions().addAction({
    title: t('my-business.main-actions.manage-menus'),
    icon: 'restaurantsPageType',
    onClick: async () => {
      await openManageMenuDashboardPanel(
        editorSDK,
        flowAPI,
        DashboardPage.menus,
        DASHBOARD_LOAD_REFERRAL_INFO.myBusiness
      );
    },
  });

  managementActionsBuilder.customActions().addAction(
    {
      title: t('my-business.custom-actions.manage-menu-items'),
      icon: 'corvid_tree__collection',
      type: 'dashboard',
      onClick: async () => {
        await openManageMenuDashboardPanel(
          editorSDK,
          flowAPI,
          DashboardPage.items,
          DASHBOARD_LOAD_REFERRAL_INFO.myBusiness
        );
      },
    },
    {
      title: t('my-business.custom-actions.manage-restaurant-pages'),
      icon: 'appManager_pagesAction',
      type: 'dashboard',
      onClick: async () => {
        const token = 'TOKEN';
        const { check, show } = editorSDK.editor.deeplink;
        const pagesPanel = {
          type: 'pagesPanel' as 'pagesPanel',
          params: [PARENT_APP_DEF_ID],
        };
        const editorDeepLinkToMenusPagesPanel = await check(token, pagesPanel);
        editorDeepLinkToMenusPagesPanel && (await show(token, pagesPanel));
      },
    }
  );

  managementActionsBuilder.learnMoreAction().set({
    id: MENU_MY_BUSINESS_PANEL_ARTICLE_ID,
  });
};
